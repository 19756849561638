import React from "react";
import Layout from "../components/reusable/Layout";
import { graphql } from "gatsby";
import Seo from "../components/reusable/Seo";
import {
  AboutUsSection,
  Hero,
  InfoSection,
} from "../styles/pages/privacy-policy";
import { Stars } from "@styled-icons/bootstrap/Stars";
import { MessageCircleOutline } from "@styled-icons/evaicons-outline/MessageCircleOutline";
import { ShieldAlt2 } from "styled-icons/boxicons-regular";
import { HeartFill } from "styled-icons/bootstrap";
import { useTranslation } from "react-i18next";
import { Bg, Text } from "../components/ui";
import { Box, Stack } from "@mui/material";

const Pledge = () => {
  const { t } = useTranslation("policy");
  const piezita =
    "https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fprivacy-policy%2FPrimero%20que%20nada.png?alt=media&token=b33013cc-aa58-41cc-b39d-45657cd4501f";

  const titleStyles = {
    fontSize: "24px",
    textAlign: "start",
    marginBottom: "40px",
    display: "block",
  };

  const bodyStyles = {
    fontSize: "18px",
    textAlign: "start",
    marginBottom: "40px",
  };
  return (
    <>
      <Seo
        canonical="https://lapieza.io/aviso-de-privacidad"
        title="En nuestra plataforma de talento, tus datos están seguros."
        description="Contamos con una política de privacidad que respalda tu seguridad mientras navegas en nuestra plataforma de talento."
        image="https://firebasestorage.googleapis.com/v0/b/lapieza-production.appspot.com/o/EmpresasATS%2Fpreviews%2FLANDING%20EMPRESAS.png?alt=media&token=3dd56d07-4544-4eef-ab5c-5b53a475137e"
      />
      <Layout>
        <Hero>
          <Box
            sx={{
              maxWidth: "462px",
              minHeight: "400px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "flex-start",
              paddingBottom: "60px",
            }}
          >
            <Text.Body
              text={t("heroTitle")}
              fontSize="23px"
              color="primary.white"
              textAlign="start"
              sx={{ display: "block" }}
            />
            <Text.Title
              text={t("heroDesc")}
              fontSize="38px"
              textAlign="start"
              color="primary.white"
            />
          </Box>
        </Hero>
        <Bg.Linear>
          <AboutUsSection>
            <div className="main-landing-container-column">
              <Text.Body
                text={t("termsFirstDesc")}
                fontSize="18px"
                textAlign="start"
                sx={{ marginBottom: "30px" }}
              />
              <div className="about-us-resume">
                <article>
                  <Text.Title
                    text={t("firstStepTitle")}
                    fontSize="38px"
                    textAlign="start"
                    sx={{ display: "block", marginBottom: "40px" }}
                  />
                  <Text.Body
                    text={t("firstStepDescription1")}
                    fontSize="18px"
                  />
                  <Text.Body
                    text={t("firstStepDescriptionBold1")}
                    fontSize="18px"
                    sx={{ marginBottom: "10px", textAlign: "start" }}
                  />
                  <Text.Body
                    text={t("firstStepDescription2")}
                    fontSize="18px"
                    sx={{
                      display: "block",
                      marginBottom: "10px",
                      marginTop: "20px",
                      textAlign: "start",
                    }}
                  />
                </article>
                <figure>
                  <img src={piezita} alt="Figura de LaPieza" />
                </figure>
              </div>
              <div className="about-us-list">
                {[
                  {
                    icon: Stars,
                    title: t("firstStepItemTitle1"),
                    description: t("firstStepItemDescription1"),
                  },
                  {
                    icon: ShieldAlt2,
                    title: t("firstStepItemTitle2"),
                    description: t("firstStepItemDescription2"),
                  },
                  {
                    icon: MessageCircleOutline,
                    title: t("firstStepItemTitle3"),
                    description: t("firstStepItemDescription3"),
                  },
                ].map((item, index) => (
                  <Stack
                    key={index}
                    spacing={1}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "10px",
                      marginBottom: "15px",
                    }}
                  >
                    <Stack
                      style={{
                        backgroundColor: "white",
                        borderRadius: "8px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "45px",
                        height: "45px",
                      }}
                    >
                      <item.icon size={22} color="#EF8E02" />
                    </Stack>

                    <Stack style={{ display: "flex", flexDirection: "column" }}>
                      <Text.Title
                        text={item.title}
                        sx={{
                          fontSize: "18px",
                          textAlign: "start",
                          marginBottom: "4px",
                          display: "block",
                        }}
                      />
                      <Text.Body
                        text={item.description}
                        sx={{
                          fontSize: "16px",
                          textAlign: "start",
                          display: "block",
                        }}
                      />
                    </Stack>
                  </Stack>
                ))}
              </div>
              <article className="about-us-footer">
                <HeartFill size={32} color="#F48221" />
                <Box sx={{ maxWidth: "550px" }}>
                  <Text.Title text={t("firstMessage")} fontSize="18px" />
                </Box>
              </article>
            </div>
          </AboutUsSection>
        </Bg.Linear>
        <InfoSection>
          <div className="info-section main-landing-container-column">
            <Text.Title
              text={t("heroTitle")}
              sx={{
                fontSize: "38px",
                textAlign: "start",
                marginBottom: "30px",
              }}
            />
            <Text.Body
              text={t("termsFirstDesc")}
              sx={{
                fontSize: "18px",
                textAlign: "start",
                marginBottom: "40px",
              }}
            />
            <div className="info-section-list">
              {[
                { title: t("termsSecondTitle"), desc: t("termsSecondDesc") },
                { title: t("termsThirdTitle"), desc: t("termsThirdDesc") },
                { title: t("termsFourthTitle"), desc: t("termsFourthDesc") },
                { title: t("termsTitle5"), desc: t("termsDesc5") },
                { title: t("termsTitle6"), desc: t("termsDesc6") },
                { title: t("termsTitle7"), desc: t("termsDesc7") },
                { title: t("termsTitle8"), desc: t("termsDesc8") },
                { title: t("termsTitle9"), desc: t("termsDesc9") },
                { title: t("termsTitle10"), desc: t("termsDesc10") },
                { title: t("termsTitle11"), desc: t("termsDesc11") },
                { title: t("termsTitle12"), desc: t("termsDesc12") },
                {
                  title: t("termsTitle13"),
                  desc: t("termsDesc13"),
                  isHtml: true,
                },
              ].map((item, index) => (
                <article key={index}>
                  <Text.Title text={item.title} sx={titleStyles} />
                  {item.isHtml ? (
                    <div dangerouslySetInnerHTML={{ __html: item.desc }} />
                  ) : (
                    <Text.Body text={item.desc} sx={bodyStyles} />
                  )}
                </article>
              ))}
            </div>
          </div>
        </InfoSection>
      </Layout>
    </>
  );
};

export default Pledge;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
